<template>
  <div>
    <a
      :href="requestLink()"
      v-if="count > 0"
      target="_blank"
      :class="customClass"
      >{{ text }} <span v-if="isShowCount">({{ count }})</span></a
    >
    <span v-else>{{ text }} ({{ count }})</span>
  </div>
</template>

<script>
export default {
  name: "OrderLink",
  props: {
    isShowCount: {
      type: Boolean,
      default: () => true,
    },
    count: {
      type: Number,
      default: () => 0,
    },
    customClass: {
      type: String,
      default: () => "",
    },
    text: {
      type: String,
      default: () => "",
    },
    tracking_id: {
      type: String,
      default: () => "",
    },
    status: {
      type: Number,
      default: () => 0,
    },
  },
  methods: {
    requestLink() {
      return `/internal-request?order_tracking_id=${this.tracking_id}&status=${this.status}`;
    },
  },
};
</script>

<style scoped></style>
